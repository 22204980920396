@import "./normalize.css";
@import "mixin";
@import "base";

/* NOTE :
   Layout and styling are separated on two parts.
   Each section as :
        - a 'Layout' subpart
        - a 'Styling' subpart
*/

/* LANDING */

/** GENERAL LAYOUT :
  The landing has 3 blocks :
  The logo (absolute)
  The text content (in the flow)
  The downward arrow (CSS sticky footer trick) **/

#landing {
  background-color: #F0F0F0;
  display:flex;
  flex-direction: column;
  justify-content:space-between;
  overflow: hidden;

  min-height: min(1000px, 100%);
  width:100%;
  padding: 0px;

  & .logo-bg {
    position: absolute;
    background-image: url('../img/logo-light.xl.png');
    background-size: cover;
    background-position:center;
    background-repeat: no-repeat;
    width: 100%;
    height: 300px;

    @include sm {
      top: -5%;
      right: -25%;
      height: 400px;
    }

    @include md {
      top: -7%;
      right: -12%;
      height: 500px;
      width: 800px;
      max-width: 80%;
      max-height: 65%;
    }

    @include xl {
      width: 1300px;
      height: 850px;
      max-height: 80%;
    }
  }

  & .content {
    display: flex;
    flex-direction:column;

    width:min(calc(100% - 40px), 82%);
    padding: 0px max(8%, 20px);

    padding-top: 250px;
    @include sm {
      padding-top: 17vh;
    }
    @include xl {
      padding-top: 20vh;
    }
  }

  & .arrow-down-container {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
    vertical-align: bottom;
  }
}

/** TEXT CONTENT STYLING **/

#landing .content {

  .title {
    @include font-title($style: normal, $weight: 800, $size: 4.5em, $line: 90px);
    margin-bottom: 10px;
  }

  .subtitle {
    @include font-title($weight: 200, $size: 36px, $line: 36px);
    margin: 15px 0px;
  }

  .subsubtitle {
    @include font-title;
    margin: 5px 0px;
  }

  & p {
    max-width: 600px;
    //line-height: 30px;
    font-weight: 200;
    font-size: 18px;
    text-align:justify;
  }
}

/* FOOTER */

/** General Layout **/

footer {
  padding-top:150px;
  ul {
    display:flex;
    flex-flow : row wrap;
    list-style:none;
    padding:5px 10px;
    margin:0px;
    li:nth-of-type(n+2):before {
      content:"\a0-\a0"
    }
    details{
      display: inline-block;
    }
    details > div{
      position:absolute;
      padding:10px;
      width:calc(100% - 20px);
      left:0px;
      overflow-y:hidden;
      bottom:40px;
      @include md{
        bottom:20px;
      }
      table {
        table-layout:auto;
        max-width:450px;
        margin:auto;
        padding:5px;
        td,th{
          padding: 0px 10px;
        }
        th{
          white-space: nowrap;
        }
      }
    }
  }
}

/** Content Styling **/
footer {
  background-color:#F0F0F0;
  font-size:0.8em;
  position:relative;
  ul, ul li {
    background-color: #F0F0F0;
  }
}
details
{
  summary::-webkit-details-marker
  {
    display:none;
  }
  & > summary:first-of-type {
    list-style-type: '';
  }
  &[open] summary{
    font-weight:600;
  }
  &:hover > summary {
    cursor: pointer;
    color: $secondary-color;
  }
  & > div {
    //box-shadow: -7px -5px 7px rgba(0,0,0,.2);
    border-top: 1px solid #999999;
    max-height: 100%;
  }


  &[open] summary ~ * {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0%    {opacity: 0; bottom:30px; max-height: 0px;}
  }
}
